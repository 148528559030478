/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

import "./layout.css"
import Navbar from "./Navbar/Navbar"
import Footer from "./footer"
import styled from "styled-components"

const Innhold = styled.nav`
  background-color: #eaeff7;
  margin: 0 auto;
  max-width: 960px;
  min-height: 90vh;
  padding: 0px 1.0875rem 1.45rem;
  padding-top: 3vh;
  padding-bottom: 10vh;
  text-align: center;
`
const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <Innhold id={"content"}>
        <main>{children}</main>
      </Innhold>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
