import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const MapPinWrap = styled.div``

const MapPin = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "mapPin" }, extension: { eq: "png" }) {
        childImageSharp {
          fluid(pngQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <MapPinWrap
      as={Link}
      target="_blank"
      to={
        "https://www.google.com/maps/place/Festningsgata+38,+4614+Kristiansand,+Norway/@58.1486645,7.989696,15.82z/data=!4m6!3m5!1s0x4638025ec1f949c7:0x45e0b8dbd0b94d07!8m2!3d58.1485081!4d7.9949787!16s%2Fg%2F11cs9h53jl"
      }
    >
      <Img
        fluid={data.file.childImageSharp.fluid}
        alt="Kart"
        style={{
          width: `30px`,
          display: "inline-block",
        }}
      />
    </MapPinWrap>
  )
}

export { MapPin }
