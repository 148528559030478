import React from "react"
import FacebookImg from "../images/social/facebook_20x20.png"
import { MapPin } from "./Images/mapPin"

const Footer = () => (
  <footer
    style={{
      background: `#dde9fe`,
    }}
  >
    <p
      style={{
        margin: `0 auto`,
        color: `black`,
        maxWidth: 960,
        padding: `5px 0`,
        verticalAlign: `middle`,
      }}
    >
      <font color="red">
        Du finner oss i Festningsgata 38!&nbsp;&nbsp; <MapPin /> <br />
      </font>
      © {new Date().getFullYear()} RMB Trafikkskole.&nbsp;&nbsp;
      <a href="tel:38092800" style={{ whiteSpace: `nowrap` }}>
        Tlf: 38 09 28 00
      </a>{" "}
      &nbsp;&nbsp;
      <a
        href="mailto:rmbtrafikkskole@online.no"
        style={{ whiteSpace: `nowrap` }}
      >
        Epost: rmbtrafikkskole@online.no
      </a>
      &nbsp;&nbsp;{" "}
      <a
        href="https://www.facebook.com/RMBtrafikkskole/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={FacebookImg}
          alt={"facebook"}
          style={{ alignContent: "bottom" }}
        />
      </a>
    </p>
  </footer>
)

export default Footer
